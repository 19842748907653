<template>
  <b-container class="test-page">
    <b-row>
      <b-col></b-col>
      <b-col class="test-title">培立优2023中考英语冲刺</b-col>
      <b-col>
        <countdown @afterCountdown="afterCountdown"></countdown>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="test-subtitle">{{ reportName() }}</b-col>
    </b-row>
    <div>
      <EnglishTestQuestion
        v-for="(question, index) in examQuestion.engExamTesDtos"
        :examQuestion="question"
        @changeRadio="changeRadio"
        @getInput="getInput"
        :key="question.questionId"
        :index="index"
        :answer="userAnswers['answerInputs'][index]['answer']"
      />
    </div>
    <b-row>
      <b-col>
        <b-button :disabled="!canSubmit" class="submit-test-paper" @click="toSubmitAnswer">
          <span class="iconfont icon-submit-fill"></span>
          <span>提交试卷</span>
        </b-button>
        <b-button class="giveUpTheExam" @click="toGiveUpTest">
          <span class="iconfont icon-fangqi"></span>
          <span>放弃考试</span>
        </b-button>
      </b-col>
    </b-row>
    <GlobalPopup
      :popupsTitle="porpContent.popupsTitle"
      :popupsbuttomA="porpContent.popupsbuttomA"
      :popupsbuttomB="porpContent.popupsbuttomB"
      @OKbutton="okButton"
      @NObutton="
        () => {
          modalShow = false;
        }
      "
      :isShow="modalShow"
    ></GlobalPopup>
  </b-container>
</template>

<script>
import countdown from "@/components/countdown/countdown.vue";
import EnglishTestQuestion from "@/components/englinsh-test/english-test-question.vue";
import GlobalPopup from "@/components/global-popup/global-popup-modal";
import {
  getExamTestById,
  englishCommonSaveAnswer,
} from "@/api/english-small.js";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      examQuestion: {
        sectionScore: 0,
        plyTkSectionId: 2,
        description: "",
        stemStatement: "",
        engExamTesDtos: [],
      },
      porpContent: "",
      modalShow: false,
      leaveIllegally: false,
      toName:"",
      canSubmit:true
    };
  },
  components: {
    EnglishTestQuestion,
    countdown,
    GlobalPopup,
  },
  created() {
    this.getTest();
  },
  computed: {
    ...mapState(["simulationExamQuestions", "userAnswers", "testEndTime"]),
  },
  // mounted(){
  //   if (this.userAnswers){
  //     this.getOldwritingAnswers(1)
  //   }
  // },
  methods: {
    ...mapMutations([
      "setSimulationExamQuestions",
      "setUserAnswers",
      "setTestEndTime",
    ]),
    reportName() {
      if (this.$route.query.text == 2) {
        return "扫雷尖兵词汇效果测评";
      } else {
        return "扫雷尖兵词汇靶向诊断";
      }
    },
    toSubmitAnswer() {
      this.porpContent = {
        popupsTitle: "确认提交答案吗？提交后将无法再修改",
        popupsbuttomA: "取消",
        popupsbuttomB: "确定",
      };
      this.modalShow = true;
      this.okButton = () => {
        this.modalShow = false;
        this.submitTest();
      };
    },
    toGiveUpTest() {
      this.porpContent = {
        popupsTitle: "是否确认放弃本次测评？已提交答案将会作废！",
        popupsbuttomA: "取消",
        popupsbuttomB: "确定",
      };
      this.modalShow = true;
      this.okButton = () => {
        this.modalShow = false;
        this.cancelTest();
      };
    },
    okButton() {},
    cancelTest() {
      // debugger;
      this.leaveIllegally = true;
      if (this.toName) {
        this.$router.replace({
          name: this.toName,
        });
      } else {
        this.$router.replace({
          name: "HomeIndex",
        });
      }
    },
    getTest() {
      getExamTestById({ examId: this.$route.query.examId }).then((res) => {
        console.log("获取靶向诊断试题", res);
        this.examQuestion = res.data.result;
        if (!sessionStorage.getItem("testEndTime")) {
          this.setTestEndTime(
            new Date().getTime() + res.data.result.countDown * 25 * 1000
          );
        }
        if (!sessionStorage.getItem("userAnswers")) {
          this.userAnswersInit(res.data.result.engExamTesDtos);
        }
      });
    },
    userAnswersInit(engExamTesDtos) {
      let answers = {
        examId: this.$route.query.examId,
        answerMinutes: 0,
        sprintId: this.$route.query.sprintId,
        stageId: this.$route.query.stage,
        answerInputs: [],
      };
      for (let item of engExamTesDtos) {
        answers["answerInputs"].push({
          questionId: item.questionId,
          answer: "",
          questionNumber: item.variation,
        });
      }
      this.setUserAnswers(answers);
    },
    afterCountdown() {
      this.submitTest();
    },
    changeRadio(checked, questionId, setionId, index) {
      console.log("index", index);
      this.userAnswers.answerInputs[index].answer = checked;
      this.setUserAnswers(this.userAnswers);
    },
    getInput(index, value) {
      this.userAnswers.answerInputs[index].answer = value;
      this.setUserAnswers(this.userAnswers);
    },
    submitTest() {
      this.leaveIllegally = true;
      let parmeter = this.userAnswers;
      let remainingTime = this.testEndTime - new Date().getTime();
      if (remainingTime <= 0) {
        parmeter["answerMinutes"] = this.examQuestion.countDown;
      } else {
        parmeter["answerMinutes"] = parseInt(
          this.examQuestion.countDown - remainingTime / 1000 / 60
        );
      }
      this.canSubmit = false
      englishCommonSaveAnswer(parmeter).then((res) => {
        this.canSubmit = true
        console.log("提交靶向诊断", res);
        if (res.data.result.isSubmit) {
          this.$router.replace({
            name: "SmallModuleTestEnd",
            query: {
              studentExamId: res.data.result.studentExamId,
              // module: 1,
              stage: this.$route.query.stage,
              text: this.$route.query.text,
            },
          });
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.leaveIllegally) {
      console.log("to.path", to.path);
      this.toName = to.name;
      if (to.path == "/login") {
        return (this.leaveIllegally = true);
      } else {
        this.porpContent = {
          popupsTitle: "是否确认放弃本次测评？已提交答案将会作废！",
          popupsbuttomA: "取消",
          popupsbuttomB: "确定",
        };
        this.modalShow = true;
        this.okButton = () => {
          this.modalShow = false;
          this.cancelTest();
        };
        return this.leaveIllegally;
      }
    }
    sessionStorage.removeItem("userAnswers");
    sessionStorage.removeItem("simulationExamQuestions");
    sessionStorage.removeItem("testEndTime");
    next();
  },
};
</script>

<style lang="scss" scoped>
.test-page {
  background: white;
  min-height: 500px;
  padding: 20px;
}
.test-title {
  margin: 5px;
  font-weight: bold;
}
.test-subtitle {
  margin: 5px;
  font-size: 30px;
  font-weight: bold;
}
.cancelButton {
  margin: 10px;
}
.submitButton {
  margin: 10px;
}
.submit-test-paper {
  background: linear-gradient(0deg, #fbab43 0%, #fbb860 100%);
  border-radius: 5px !important;
  border: none !important;
  margin: 0 2rem;
  font-weight: bold;
  .icon-submit-fill {
    padding-right: 2px;
  }
}
.giveUpTheExam {
  background: linear-gradient(0deg, #f0f0f0 0%, #e5e5e5 100%);
  border-radius: 5px !important;
  border: none !important;
  color: #333333 !important;
  margin: 0 2rem;
  font-weight: bold;
  .icon-fangqi {
    color: #b1b0b0;
    padding-right: 2px;
  }
}
</style>